import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import * as packageJson from '../package.json';
import Playground from '../../../src/Playground';
import { UiInput } from '../src';
import { UiIcon } from '@uireact/icons';
import { UiSpacing } from '@uireact/foundation';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <h1 {...{
      "id": "uiinput"
    }}>{`UiInput`}</h1>
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/packages/form/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`Component used to render input fields`}</p>
    </blockquote>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <blockquote>
      <p parentName="blockquote">{`npm i @uireact/foundation @uireact/form`}</p>
    </blockquote>
    <h2 {...{
      "id": "uiinput-with-placeholder"
    }}>{`UiInput with placeholder`}</h2>
    <Playground __position={1} __code={'<UiInput\n  placeholder=\"First Name\"\n  onChange={value => {\n    console.log(value)\n  }}\n/>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiInput,
      UiIcon,
      UiSpacing,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiInput placeholder="First Name" onChange={value => {
        console.log(value);
      }} mdxType="UiInput" />
    </Playground>
    <h2 {...{
      "id": "uiinput-with-size"
    }}>{`UiInput with size`}</h2>
    <Playground __position={2} __code={'<UiInput\n  placeholder=\"First Name\"\n  onChange={value => {\n    console.log(value)\n  }}\n  size=\"xlarge\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiInput,
      UiIcon,
      UiSpacing,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiInput placeholder="First Name" onChange={value => {
        console.log(value);
      }} size="xlarge" mdxType="UiInput" />
    </Playground>
    <h2 {...{
      "id": "uiinput-with-label"
    }}>{`UiInput with label`}</h2>
    <Playground __position={3} __code={'<UiInput label=\"First Name\" labelOnTop />'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiInput,
      UiIcon,
      UiSpacing,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiInput label="First Name" labelOnTop mdxType="UiInput" />
    </Playground>
    <h2 {...{
      "id": "uiinput-with-icon"
    }}>{`UiInput with icon`}</h2>
    <Playground __position={4} __code={'<UiInput\n  label=\"Search\"\n  placeholder=\"Type your search\"\n  labelOnTop\n  icon={<UiIcon icon=\"Search\" />}\n/>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiInput,
      UiIcon,
      UiSpacing,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiInput label="Search" placeholder="Type your search" labelOnTop icon={<UiIcon icon="Search" mdxType="UiIcon" />} mdxType="UiInput" />
    </Playground>
    <h2 {...{
      "id": "uiinput-disabled"
    }}>{`UiInput disabled`}</h2>
    <Playground __position={5} __code={'<UiInput label=\"First Name\" disabled />'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiInput,
      UiIcon,
      UiSpacing,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiInput label="First Name" disabled mdxType="UiInput" />
    </Playground>
    <h2 {...{
      "id": "uiinput-with-state"
    }}>{`UiInput with state`}</h2>
    <Playground __position={6} __code={'<UiInput label=\"First Name\" category=\"positive\" />'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiInput,
      UiIcon,
      UiSpacing,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiInput label="First Name" category="positive" mdxType="UiInput" />
    </Playground>
    <h2 {...{
      "id": "uiinput-with-error-state-and-error-message"
    }}>{`UiInput with error state and error message`}</h2>
    <Playground __position={7} __code={'<UiInput label=\"First Name\" error=\"Some error\" category=\"error\" />'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiInput,
      UiIcon,
      UiSpacing,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiInput label="First Name" error="Some error" category="error" mdxType="UiInput" />
    </Playground>
    <h2 {...{
      "id": "uiinput-for-passwords"
    }}>{`UiInput for passwords`}</h2>
    <Playground __position={8} __code={'<UiInput label=\"Password\" labelOnTop type=\"password\" />'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiInput,
      UiIcon,
      UiSpacing,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiInput label="Password" labelOnTop type="password" mdxType="UiInput" />
    </Playground>
    <h2 {...{
      "id": "uiinput-for-numbers"
    }}>{`UiInput for numbers`}</h2>
    <Playground __position={9} __code={'<UiInput label=\"Number\" labelOnTop type=\"number\" value=\"123\" />'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiInput,
      UiIcon,
      UiSpacing,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiInput label="Number" labelOnTop type="number" value="123" mdxType="UiInput" />
    </Playground>
    <h2 {...{
      "id": "uiinput-for-datepicker"
    }}>{`UiInput for datepicker`}</h2>
    <Playground __position={10} __code={'<UiInput label=\"Date\" labelOnTop type=\"date\" />'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiInput,
      UiIcon,
      UiSpacing,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiInput label="Date" labelOnTop type="date" mdxType="UiInput" />
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <Props of={UiInput} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      